<template>
  <div>
    <component  v-bind:is="currentTabComponent"></component>
  </div>
</template>
<script>
import indexhome from "../index.vue";
import mobilehome from "../newwap/index.vue";
export default {
  components: {
    indexhome,
    mobilehome,
  },
  data() {
    return {
    };
  },
  methods: {
    // // 添加判断方法
    isMobile() {
      this.flag = navigator.userAgent.match( /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
      if (this.flag === null) {
        return false;
        // console.log("pc端");
      } else {
        // console.log("移动端");
        return true;
      }
    },
  },
  computed: {
    currentTabComponent() {
      if (this.isMobile()) {
        return "mobilehome";
      } else {
        return "indexhome";
      }
    },
  },
};
</script>
