<template>
  <div class="flex-col page">
    <Newheader />
    <div class="flex-col">
      <div data-aos="fade-up" class="swiper-container s1" >
        <div class="swiper-wrapper">
          <div class="swiper-slide">

            <div class="flex-col section_1">
            
                <video  id="index_video"
													src="https://ckmco-video.oss-cn-shanghai.aliyuncs.com/2022/videos/home.b57fc744.mp4"
                          class="image_33"
													style="object-fit: cover;" x5-playsinline="true"
													webkit-playsinline="true" playsinline="true"
													x5-video-player-type="h5" x5-video-player-fullscreen="true"
													x5-video-ignore-metadata="true" autoplay="" muted=""
													 loop=""></video>
              <div class="flex-col group_42">

                <div class="flex-col group_2">
                  <div class="flex-col">
                    <div class="flex-col items-start">
                     
                      <span class="text_6" style="font-size: 8.4rem;line-height: 1.5;margin: 0;">为热爱发电</span>
                      <span class="text_6" style="font-size: 8.4rem;line-height: 1.5;margin: 0;">源自未被定义</span>
                    </div>
                    <div class="flex-col group_5">
                      <div class="flex-row group_6">
                        <span class="text_7 hoverbtn " @click="navPath('/brand')">了解品牌</span>
                        <span class="text_8 hoverbtn text_9" @click="navPath('/contact')">联系我们</span>
                      </div>
                      <div class="section_3"></div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div class="swiper-slide">

            <div class="flex-col section_1">
              <img
                src="https://ckmco-video.oss-cn-shanghai.aliyuncs.com/2022/images/banners2.18767745.png"
                class="image_33" />
              <div class="flex-col group_42">

                <div class="flex-col group_2">
                  <div class="flex-col">
                    <div class="flex-col items-start">
                      <img
                        src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16607016338981222074.png" />

                      <span class="text_6">新世代电轻摩</span>
                    </div>
                    <div class="flex-col group_5">
                      <div class="flex-row group_6">
                        <span class="text_7" @click="navPath('/i-onex')" >了解更多</span>
                        <span class="text_8 text_9" @click="navPath('/drive')" >预约试驾</span>
                      </div>
                      <div class="section_3"></div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div class="swiper-slide">

            <div class="flex-col section_1">
              <img src="https://ckmco-video.oss-cn-shanghai.aliyuncs.com/2022/images/banners3.363da1e3.png" class="image_33" />
              <div class="flex-col group_42">
                <div class="flex-col group_2">
                  <div class="flex-col">
                    <div class="flex-col items-start">
                      <p class="flex-row items-start" style="font-size: 8.8rem;color:#fff;line-height: 1;">赛道级电摩<img  style="margin-left: 3rem;" src="@/assets/images/ws7.png" alt=""></p>
                      <span class="text_6">给够动力 就够给力</span>
                    </div>
                    <div class="flex-col group_5">
                      <div class="flex-row group_6">
                        <span class="text_7" @click="navPath('/s7')">了解更多</span>
                        <span class="text_8 text_9" @click="navPath('/drive')">预约试驾</span>
                      </div>
                      <div class="section_3"></div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div class="swiper-slide">

            <div class="flex-col section_1">
              <img src="https://ckmco-video.oss-cn-shanghai.aliyuncs.com/2022/images/ilike.c08bb649.png" class="image_33" />
              <div class="flex-col group_42">

                <div class="flex-col group_2">
                  <div class="flex-col">
                    <div class="flex-col items-start">
                      <img src="@/assets/images/index/ilikelogo.png" class="image_3" />
                      <span class="text_6">经典进化 新生登场</span>
                    </div>
                    <div class="flex-col group_5">
                      <div class="flex-row group_6">
                        <span class="text_7" @click="navPath('/i-like')">了解更多</span>
                        <span class="text_8 text_9" @click="navPath('/drive')">预约试驾</span>
                      </div>
                      <div class="section_3"></div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-pagination swiper-pagination1"></div>
      </div>

      <div data-aos="fade-up" class="swiper-container s2">
        <div class="swiper-wrapper">
          <div v-for="(item,index) in list" :key="index" class="swiper-slide">
            <a :href="item.href">
            <div class="flex-row group_8">
                <img
                :src="item.img"
                class="image_9" />
              <div class="flex-col section_4">
                <div class="flex-col">
                  <div class="flex-col group_10">
                      <div class="group_11">
                          <span class="text_10">{{item.text1}}</span>
                          <span class="text_11">{{item.text2}}</span>
                      </div>
                      <div class="group_12" >
                          <span class="text_12">{{item.text3}}</span>
                          <span class="text_12">{{item.text4}}</span>
                      </div>
                  </div>
                  <div class="flex-col group_13">
                    <a :href="item.href">
                      <div class="flex-row group_6">
                        <span class="text_15">了解更多</span>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </a>
          </div>
        </div>
        <div class="swiper-pagination swiper-pagination2" style="position:relative;top:50px;left:20px !important"></div>
      </div>
    </div>
    <div class="flex-col group_15">
      <div data-aos="fade-up" class="flex-col items-center section_6">

        <video id="index_video" class="image_36"
          src="https://ckmco-video.oss-cn-shanghai.aliyuncs.com/2022/videos/ionex.mp4"
          style="object-fit: cover; width: 100%; height: 100%" x5-playsinline="true" webkit-playsinline="true"
          playsinline="true" x5-video-player-type="h5" x5-video-player-fullscreen="true" x5-video-ignore-metadata="true"
          autoplay="" muted="" loop=""></video>
        <div class="flex-col group_45">
          <img
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16607016336107170596.png"
            class="image_13" />
          <div class="group_16">
            <span class="text_17">『Ionex车能网』智能安全出行生态系统</span>
            <span class="text_18">全面开创两轮智能出行新体验是全球电动车市场最佳智能化解决方案</span>
          </div>
          <div @click="navPath('/ionex')" class="hoverbtn text_19">
            <span class="text_19">了解更多</span>
            <div class="section_7">
            
            </div>
          </div>
        
        </div>
      </div>
      <div data-aos="fade-up" class="flex-col items-center group_17">
        <div class="flex-col items-center">
          <img
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16607016335125113629.png"
            class="image_14" />
          <span class="text_20" style="font-family: Albbr;">热爱之心 源自未被定义</span>
        </div>
        <img
          src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16607016335148826986.png"
          class="image_15" />
        <div class="flex-col group_19">
          <div class="group_20">
            <span class="text_21">KYMCO打造一系列经典车型，组成摩托家族矩阵，<br></span>
            <span class="text_22">成长为一家全球性的powersport集团，业务遍及100多个国家。</span>
          </div>
          <div class="flex-col items-center group_21">
            <span @click="navPath('/brand')" class="text_23 url">了解品牌</span>
            <div class="section_8">
              <!--*-->
            </div>
          </div>
        </div>
      </div>
      <div class="flex-col group_22">
        <div data-aos="fade-up" class="justify-between section_9">
          <div class="flex-col group_23">
            <div>
              <span class="text_24" style="font-family: Albbr;">KYMCO APP<br></span>
              <span class="text_25" style="font-family: Albbr;">集结实用工具、直觉操作与车辆资讯，</span>
              <br/>
              <span class="text_26" style="font-family: Albbr;">移动生活有了APP更加轻松便利。</span>
            </div>
            <img
              src="../assets/images/ewm.jpg"
              class="image_16" />
          </div>
          <img
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16607016333944516504.png"
            class="image_17" />
        </div>


        <div class="ff_indexPage section_10">
          <div class="mlist news module ff_slider">


            <div class="container_content">

              <div class="content_wrapper slider" data-slider-num='3' data-slider-loop="1">
                <div data-aos="fade-up" class="clearfix content_list" style="display:flex;justify-content: space-between;align-items: center;">
                  <div v-for="t in newList"  class="item_block_0 item_block wow" style="animation-delay:.0s;width: 500px;">
                    <div class="item_index">01</div>
                    <a :href="t.href" class="item_box">
                      <div  class="item_img" target="_blank">
                        <img :src="t.url" />
                        <div class="item_mask"></div>
                      </div>
                      <div class="clearfix item_wrapper">
                        <div class="clearfix item_info">
                          <div class="date_wrap">
                            <span style="font-family: Albbr;" class="year">{{ t.date1 }}</span><i class="time-connect">-</i><span class="d" style="font-family: Albbr;">{{ t.date2 }}</span>
                          </div>
                          <p class="title ellipsis" style="font-family:'Albb';font-size:18px;">{{ t.title }}</p>
                          <p class="subtitle ellipsis"></p>
                        </div>
                        <div class="date_wrap">
                          <span style="font-family: Albbr;" class="year">{{ t.date1 }}</span><i class="time-connect">-</i><span class="d" style="font-family: Albbr;">{{ t.date2 }}</span>
                        </div>
                        <div class="item_des">
                          <p class="description" style="font-size:14px; font-family:'Albbr';font-weight:lighter !important">{{ t.desc }}</p>
                        </div>
                        <span href="#" class="details hide">
                          MORE<i class="fa fa-angle-right" aria-hidden="true"></i>
                        </span>
                      </div>
                      <div class="item_index">01</div>
                    </a>
                    <a href="#" class="details">
                      MORE<i class="fa fa-angle-right" aria-hidden="true"></i>
                    </a>
                    <div class="clearfix item_tags">
                      <i class="fa fa-tags"></i>
                      <a href="#">
                        行业资讯
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <newfooter />
      </div>
    </div>
  </div>
</template>

<script>

export default {

  data() {
    return {
      show: true,
      newList: [
        {
          url: "https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16607016331896785882.png",
          title: "KYMCO光阳电动-豪华产品矩阵闪耀2021北京国际摩展",
          desc: "5月28日，『KYMCO光阳电动』携电摩F9...",
          date1: "2021",
          date2: "05-29",
          href:"https://mp.weixin.qq.com/s/Wg03ieOH4koiVmmmyEUNng"
        },
        {
          url: "https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16607016331918602961.png",
          title: "光阳与速珂, 电斐签署战略协议, 顶级制造+酷和好玩+热血新锐",
          desc: "5月20日常州光阳摩托车有限公司举行签约仪式，常州光阳...",
          date1: "2021",
          date2: "05-20",
          href:"https://mp.weixin.qq.com/s?__biz=Mzg2Nzc1Mzk2NA==&mid=2247485791&idx=1&sn=0e20ef708127acd8b4c2dc363c242f5c&source=41#wechat_redirect",
        },
        {
          url: "https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16607016332970830791.png",
          title: "KYMCO Ionex 3.0「全面超越」發表會 四款125cc等級...",
          desc: "2021年3月18日，台北訊-全球機車專業品牌光陽工業...",
          date1: "2021",
          date2: "05-29",
          href:"https://www.kymco.com.tw/news/766-KYMCO-Ionex-3.0%E3%80%8C%E5%85%A8%E9%9D%A2%E8%B6%85%E8%B6%8A%E3%80%8D%E7%99%BC%E8%A1%A8%E6%9C%83-%E5%9B%9B%E6%AC%BE125cc%E7%AD%89%E7%B4%9A%E9%9B%BB%E5%8B%95%E6%A9%9F%E8%BB%8A%E9%9C%87%E6%92%BC%E7%99%BB%E5%A0%B4"
        }
      ],
      list:[{
        href:"https://mp.weixin.qq.com/s/phrfCaxdfg2DqS4xdJ0mxw",
        img:require("../assets/images/ban1.png"),
        text1:"四城试驾品鉴会",
        text2:"火热预约中",
        text3:"苏州、成都、郑州、西安",
        text4:"多重礼遇 乐享、独享、尽享！"
      },
      {
        href:"https://mp.weixin.qq.com/s/sIuTdiFnTfEBITpNI2NpGw",
        img:require("../assets/images/ban2.png"),
        text1:"你说耀有光",
        text2:"S7线上发布会精彩全回顾",
        text3:"一张图了解赛道级电摩",
        text4:""
      },
      {
        href:"https://mp.weixin.qq.com/s/Wg03ieOH4koiVmmmyEUNng",
        img:require("../assets/images/ban3.png"),
        text1:"KYMCO光阳电动豪华产品矩阵",
        text2:"闪耀2021北京国际摩展",
        text3:"KYMCO引领行业发展的创新实力与雄心壮志",
        text4:"为都市消费者打造多重维度的豪华智能出行体验",
      },
    ],
    };
    
  },
  mounted() {
    this.$nextTick(() => {

      var mySwiper = new Swiper('.s1', {
        autoplay: {
          delay: 5000,

        },
        speed: 500,
        allowTouchMove: false,
        lazy: {
          loadPrevNext: true,
          loadPrevNextAmount: 3,
        },
        centeredSlides: true,
        loop: true,
        slidesPerView: 'auto',
        on: {
          slideChangeTransitionEnd: function () {
            //this.slides.transition(this.params.autoplay.delay+this.params.speed).transform('translate3d(-60px, 0, 0)');
          },
          slideChangeTransitionStart: function () {
            $(".s1 .slick-active").removeClass("swiper-pagination-bullet-actives")
            $(".s1 .slick-active").eq(this.realIndex).addClass("swiper-pagination-bullet-actives")
            // this.slides.transition(this.params.speed).transform('translate3d(0, 0, 0)');
          },
        },
        pagination: {
          el: '.swiper-pagination1',
          clickable: true,
          renderBullet: function (index, className) {
            return `<li role="presentation"  class="slick-active active ${className}"><button type="button" role="tab"
						id="slick-slide-control02" aria-controls="slick-slide02" aria-label="3 of 3" tabindex="0"
						aria-selected="true">3<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12"
							preserveAspectRatio="none">
							<circle cx="6" cy="6" r="5" class="background"></circle>
							<circle cx="6" cy="6" r="5" class="annulus"></circle>
						</svg></button></li>`;
          },
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      });
      //鼠标覆盖停止自动切换 
      mySwiper.el.onmouseover = function(){ mySwiper.autoplay.stop(); } 
      //鼠标离开开始自动切换 
      mySwiper.el.onmouseout = function(){ mySwiper.autoplay.start(); }
      setTimeout(() => {
        $(".s1 .slick-active").eq(0).addClass("swiper-pagination-bullet-actives")
      }, 10);


      new Swiper('.s2', {
        autoplay: {
          delay: 3000,

        },
        speed: 500,
        allowTouchMove: false,
        lazy: {
          loadPrevNext: true,
          loadPrevNextAmount: 3,
        },
        centeredSlides: true,
        loop: true,
        slidesPerView: 'auto',
        on: {
          slideChangeTransitionEnd: function () {
            //this.slides.transition(this.params.autoplay.delay+this.params.speed).transform('translate3d(-60px, 0, 0)');
          },
          slideChangeTransitionStart: function () {
            $(".s2 .slick-active").removeClass("swiper-pagination-bullet-actives")
            $(".s2 .slick-active").eq(this.realIndex).addClass("swiper-pagination-bullet-actives")
            // this.slides.transition(this.params.speed).transform('translate3d(0, 0, 0)');
          },
        },
        pagination: {
          el: '.swiper-pagination2',
          clickable: true,
          renderBullet: function (index, className) {
            return `<li role="presentation"  class="slick-active active ${className}"><button type="button" role="tab"
						id="slick-slide-control02" aria-controls="slick-slide02" aria-label="3 of 3" tabindex="0"
						aria-selected="true">3<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12"
							preserveAspectRatio="none">
							<circle cx="6" cy="6" r="5" class="background"></circle>
							<circle cx="6" cy="6" r="5" class="annulus"></circle>
						</svg></button></li>`;
          },
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      });

      setTimeout(() => {
        $(".s2 .slick-active").eq(0).addClass("swiper-pagination-bullet-actives")
      }, 10);

    })
  },

  methods: {
   
  },
};
</script>
<style lang="scss">
 .swiper-pagination li{
  outline: none !important;
}
.s1 {
  .swiper-pagination-bullet {
    opacity: 1 !important;
  }

  .swiper-pagination-bullet {
    background-color: transparent !important;
  }
}

.s2 {
  .swiper-pagination-bullet {
    opacity: 1 !important;
  }

  .swiper-pagination-bullet {
    background-color: transparent !important;
  }
}
</style>
<style scoped lang="scss">
.text_8 {
  margin-left: 24px;
  font-family: Albbr;
}

.swiper-pagination {
  bottom: 120px;
}

.swiper-container {
  position: relative;
}

.page {
  background-color: #ffffff;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.text_19 {
  margin-top: 55px;
  color: #ffffff;
  font-size: 20px;

  line-height: 20px;
}

.text_17 {
  color: #ffffff;
  font-size: 28px;

  line-height: 40px;
}

.text_18 {
  color: #ffffff;
  font-size: 28px;

  line-height: 40px;
}

.text_20 {
  margin-top: 29px;
  color: #000;
  font-size: 28px;

  line-height: 27px;
}

.text_21 {
  color: #000;
  font-size: 24px;
  font-family: 'Albbr';
  line-height: 36px;
}

.text_22 {
  color: #000;
  font-size: 20px;
  font-family: 'Albbr';
  line-height: 36px;
}

.text_23 {
  color: #007aff;
  font-size: 20px;

  line-height: 19px;
}

.image_20 {
  margin-left: 0;
}

.text_27 {
  color: #000;
  font-size: 18px;

  font-weight: 700;
  line-height: 18px;
}

.text_28 {
  margin-left: 109px;
  color: #000;
  font-size: 18px;

  font-weight: 700;
  line-height: 18px;
}

.text_29 {
  margin-left: 57px;
  color: #000;
  font-size: 18px;

  font-weight: 700;
  line-height: 18px;
}

.text_30 {
  color: #000;
  font-size: 14px;

  line-height: 14px;
}

.view_2 {
  align-self: flex-start;
}

.view_3 {
  align-self: center;
}

.view_4 {
  align-self: flex-end;
}

.text_33 {
  color: #000;
  font-size: 14px;

  line-height: 10px;
}

.text_34 {
  color: #000;
  font-size: 14px;

  line-height: 10px;
}

.text_35 {
  color: #000;
  font-size: 14px;

  line-height: 10px;
}

.text_42 {
  margin-top: 29px;
  align-self: flex-start;
  color: #ffffff;
  font-size: 14px;

  line-height: 14px;
}

.text_24 {
  color: #000;
  font-size: 58px;

  font-weight: 700;
  line-height: 75px;
}

.text_25 {
  color: #000;
  font-size: 26px;

  line-height: 66px;
}

.text_26 {
  color: #000;
  font-size: 26px;

  line-height: 40px;
}

.text_31 {
  color: #000;
  font-size: 14px;

  line-height: 15px;
}

.text_32 {
  margin-left: 177px;
  color: #000;
  font-size: 14px;

  line-height: 15px;
}

.text_36 {
  color: #ffffff;
  font-size: 18px;

  font-weight: 700;
  line-height: 18px;
}

.text_37 {
  margin-left: 47px;
  color: #ffffff;
  font-size: 18px;

  font-weight: 700;
  line-height: 17px;
}

.text_38 {
  margin-left: 48px;
  color: #ffffff;
  font-size: 18px;

  font-weight: 700;
  line-height: 17px;
}

.text_6 {
  margin-top: 34px;
  color: #ffffff;
  font-size: 36px;
  font-family: Albbr;
  line-height: 34px;
}

.text_10 {
  color: #000;
  font-size: 36px;
  font-family: Albbr;
  font-weight: 700;
  line-height: 43px;
  display: block;
}

.text_11 {
  color: #000;
  font-size: 36px;
  font-family: Albbr;
  font-weight: 700;
  line-height: 43px;
}

.text_12 {
  color: #000;
  font-size: 24px;
  font-family: Albbr;
  line-height: 30px;
}

.text_13 {
  color: #000;
  font-size: 24px;

  font-weight: 700;
  line-height: 30px;
}

.text_14 {
  color: #000;
  font-size: 24px;

  line-height: 30px;
}

.text_15 {
  color:black;
  font-size: 20px;
  line-height: 20px;
  transition: all .5s;
  padding-bottom: 10px;
  border-bottom: 1px solid black;
  font-family: Albbr;
  &:hover {
    color: #007aff;
    border-bottom: 1px solid #007aff;
  }
}

.text_16 {
  color: #000;
  font-size: 20px;

  line-height: 19px;
}

.text_43 {
  color: #ffffff;
  font-size: 12px;

  line-height: 13px;
}

.text_44 {
  color: #ffffff;
  font-size: 12px;

  line-height: 13px;
}

.text_45 {
  color: #ffffff;
  font-size: 12px;

  line-height: 13px;
}

.text_46 {
  color: #ffffff;
  font-size: 12px;

  line-height: 13px;
}

.text_47 {
  color: #ffffff;
  font-size: 12px;

  font-weight: 700;
  line-height: 10px;
}

.text_7 {
  color: #ffffff;
  font-size: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #fff;
  font-family: Albbr;
}
.text_9 {
  color: #ffffff;
  font-size: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #fff;
}

.text_39 {
  color: #ffffff;
  font-size: 16px;

  font-weight: 300;
  line-height: 26px;
}

.text_40 {
  color: #ffffff;
  font-size: 16px;

  font-weight: 300;
  line-height: 26px;
}

.text_41 {
  color: #ffffff;
  font-size: 16px;

  font-weight: 300;
  line-height: 26px;
}

.group_15 {
  margin-top: 90px;
}

.section_1 {
  padding-bottom: 61px;

  position: relative;
}

.group_8 {
  padding: 30px 100px 0;
}

.section_6 {
  padding: 219px 0 218px;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16607016335177762523.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.group_17 {
  margin-top: 100px;
  align-self: center;
}

.group_22 {
  margin-top: 87px;
}

.image_33 {
  width: 100vw;
  height: 100vh;
  object-fit: cover;


}

.group_42 {
  position: absolute;
  left: 0;
  top: 0;
}

.image_9 {
  flex: 1 1 auto;
  width: 50%;
  height: 550px;
  object-fit: cover;
}

.section_4 {
  padding: 153.5px 60px 58px 88px;
  flex-shrink: 0;
  background-color: #eaeaea;
  width: 50%;
  height: 550px;
}

.image_13 {
  width: 629px;
  height: 58px;
}

.group_16 {
  margin-top: 42px;
  text-align: center;
}

.section_7 {
  margin-top: 5px;
  background-color: #ffffff;

  height: 3px;
}

.image_15 {
  margin-top: 48px;
  width: 1200px;
  height: 550px;
}

.group_19 {
  margin-top: 51px;
  width: 685px;
}

.section_9 {
  padding: 150px 94px 179px 359px;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16607016333222784286.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.section_10 {
  padding: 98px 159px 76px;
  background-color: #eaeaea;
}

.section_12 {
  padding: 90px 96px 140px 100px;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16607016316207594965.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.group_2 {
  margin-left: 202px;
  margin-top: 352px;
  align-self: flex-start;
  
}

.group_14 {
  margin-top: 63px;
}

.image_14 {
  width: 431px;
  height: 41px;
}

.group_20 {
  line-height: 36px;
  text-align: center;
}

.group_21 {
  margin-top: 47px;
}

.group_23 {
  margin-top: 121.5px;
  width: 550px;
}

.image_17 {
  width: 826px;
  height: 751px;
}

.group_26 {
  margin-top: 30px;
}

.group_27 {
  margin-top: 12px;
}

.divider {
  margin-top: 28px;
}

.group_29 {
  padding: 21px 0;
  align-self: flex-start;
  width: 1178px;
}

.group_30 {
  padding-bottom: 31px;
  border-bottom: solid 1px #ffffff;
}

.group_32 {
  margin-top: 48px;
}

.group_7 {
  margin-top: 335px;
}

.group_10 {
  align-self: flex-start;
  width: 100%;
}

.group_13 {
  margin-top: 43px;
}

.image_4 {
  width: 20px;
  height: 20px;
}

.image_10 {
  margin: 7px 0 9px 443px;
}

.image_6 {
  width: 36px;
  height: 36px;
}

.image_11 {
  margin-left: 37px;
}

.image_12 {
  margin: 7px 0 9px 36px;
}

.section_8 {
  margin-top: 6px;
  background-color: #007aff;
  width: 76px;
  height: 3px;
}

.image_16 {
  margin-top: 82px;
  align-self: flex-start;
  width: 145px;
  height: 145px;
}

.image_18 {
  margin-left: 50px;
  flex: 1 1 500px;
  width: 500px;
  height: 340px;
}

.group_28 {
  margin-right: 159px;
}

.section_11 {
  background-color: #000000;
  width: 500px;
  height: 1px;
}

.image_22 {
  width: 191px;
  height: 31px;
}

.group_31 {
  margin: 7px 0 6px;
}

.group_36 {
  margin-top: 13px;
}

.group_5 {
  margin-top: 53px;
}

.image_34 {
  margin: 7px 37px 9px 0;
}

.image_35 {
  margin-right: 36px;
}

.image_8 {
  margin: 7px 0 9px;
}

.group_11 {
  align-self: flex-start;
}

.group_12 {
  margin-top: 20.5px;
  line-height: 30px;
  display: flex;
  flex-direction: column;
}

.group_6 {
  padding-bottom: 5px;
  cursor: pointer;
}

.section_5 {
  margin-left: 106px;
  align-self: flex-start;
  background-color: #000000;
  width: 76px;
  height: 3px;
  border-left: solid 76px #007aff;
}

.group_34 {
  width: 260px;
}

.image_24 {
  margin-right: 4px;
  width: 100px;
  height: 100px;
}

.group_37 {
  margin-top: 23px;
  line-height: 13px;
  height: 13px;
}

.group_38 {
  margin-right: 4px;
}

.image_3 {
  width: 440px;
  height: 77px;
}

.section_3 {
  margin-left: 105px;
  align-self: flex-start;
  background-color: #ffffff;
  width: 76px;
  height: 3px;
  border-left: solid 76px #ffffff;
  display: none;
}

.image_23 {
  width: 260px;
  height: 22px;
}

.group_35 {
  margin-top: 15px;
  line-height: 26px;
}

.image_26 {
  margin-left: 41px;
  width: 40px;
  height: 33px;
}

.image_27 {
  margin-left: 41px;
  width: 41px;
  height: 34px;
}

.image_28 {
  margin-left: 41px;
  width: 30px;
  height: 36px;
}

.image_29 {
  margin-left: 41px;
}

.image_30 {
  margin-left: 41px;
  width: 39px;
  height: 36px;
}

.image_31 {
  margin-left: 41px;
}

.text_19 {
  margin-top: 55px;
  align-self: center;
  color: #ffffff;
  font-size: 20px;

  line-height: 20px;
}

.text_17 {
  color: #ffffff;
  font-size: 20px;

  line-height: 40px;
}

.text_18 {
  color: #ffffff;
  font-size: 20px;

  line-height: 40px;
  display: block;
}

.section_6 {
  padding: 219px 0 218px;
  height: 700px;
  position: relative;
}

.image_13 {
  align-self: center;
  width: 629px;
  height: 58px;
}

.group_16 {
  margin-top: 42px;
  text-align: center;
}

.section_7 {
  margin-top: 5px;
  align-self: center;
  background-color: #ffffff;

  height: 3px;
}

.image_36 {
  width: 100vw;
  height: 36.4583vw;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
}

.group_45 {
  width: 887px;
  position: relative;
}
</style>